import React from 'react';

import { TrainingSession } from 'models';

import can from 'helpers/can';
import { __, n__ } from 'helpers/i18n';
import { pathToTrainingSessionDetails } from 'helpers/paths';

import { Date as DateComponent, Link, Tag, Text } from 'components';

type Props = {
  session: TrainingSession;
};

const TrainingSessionItem = ({ session }: Props) => {
  const canUpdate = can({
    perform: 'update',
    on: session,
  });

  const durationText = session.durationInHours
    ? n__('%1 hour', '%1 hours', parseFloat(session.durationInHours))
    : null;
  const hasDate = !!(session.startDate || session.endDate);
  const isLater =
    new Date(session.startDate || session.endDate || '') > new Date();
  const sessionName = session.name || __('Untitled session');

  return (
    <div className="flex mt-4 items-start">
      <div className="flex-grow flex flex-col gap-1">
        <Text preset="14bs6">
          {canUpdate ? (
            <Link
              to={pathToTrainingSessionDetails(session.id)}
              isPrimary
              openInNewTab
              withOpenInNewTabIcon={false}
            >
              {sessionName}
            </Link>
          ) : (
            sessionName
          )}
        </Text>
        <Text preset="13s7" color="grey">
          {hasDate
            ? __(
                'From %1 to %2',
                <DateComponent value={session.startDate} />,
                <DateComponent value={session.endDate} />
              )
            : __('No date')}
          {durationText && (
            <>
              {' • '}
              <Text preset="13bs7">{durationText}</Text>
            </>
          )}
        </Text>
      </div>
      {isLater && <Tag className="shrink-0">{__('Upcoming')}</Tag>}
    </div>
  );
};

export default TrainingSessionItem;
