import React from 'react';

import { __ } from 'helpers/i18n';
import { pathToMatrixList } from 'helpers/paths';

import { EmptyStateWithIcon, Link } from 'components';

const NoMatrices = () => {
  return (
    <EmptyStateWithIcon
      iconName="table_chart"
      title={__('No skills matrix has been created yet.')}
      description={__(
        'Create your first skills matrix from the %1 page.',
        <Link to={pathToMatrixList()} isPrimary>
          {__('Matrices and skills')}
        </Link>
      )}
      inBox={false}
    />
  );
};

export default NoMatrices;
