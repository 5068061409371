import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PeopleReviewDimension } from 'models';

import { __, n__ } from 'helpers/i18n';
import { pathToPeopleReviewDimension } from 'helpers/paths';
import { zendeskURL } from 'helpers/zendesk';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  ContentContainer,
  FetchContainer,
  Link,
  PageHeader,
  PageTitle,
  SimpleTable,
  Text,
} from 'components';

type AfterDataLoaderProps = DataLoaderProvidedProps & {
  peopleReviewDimensions: Array<PeopleReviewDimension>;
};

const Dimensions = ({
  peopleReviewDimensions,
  hasError,
  isFetching,
}: AfterDataLoaderProps) => {
  const documentationURL = zendeskURL({
    fr: '9509644754833-Paramétrer-une-campagne-de-People-Review',
    en: '9509644754833-Setup-of-a-People-Review-Campaign',
  });

  return (
    <>
      <PageTitle title={__('Dimension Management')} />
      <PageHeader title={__('Dimension Management')} />

      <ContentContainer>
        <div className="mb-4">
          <Text>
            {__(
              'The people review dimensions are the criteria by which managers will evaluate participants.To find out more about using the dimensions, visit our %1.',
              <Link isPrimary to={documentationURL} openInNewTab>
                {__('help center')}
              </Link>
            )}
          </Text>
        </div>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => (
            <SimpleTable
              className="bg-ui-bg rounded-lg"
              columns={[
                {
                  header: __('Dimension name'),
                  cell: peopleReviewDimension => (
                    <Text preset="14s6">
                      <Link
                        to={pathToPeopleReviewDimension(
                          peopleReviewDimension.id
                        )}
                      >
                        {peopleReviewDimension.label}
                      </Link>
                    </Text>
                  ),
                },
              ]}
              rows={peopleReviewDimensions}
              keyFn={peopleReviewDimension => peopleReviewDimension.id}
              footer={
                <Text preset="14s6">
                  {n__(
                    '%1 dimension',
                    '%1 dimensions',
                    peopleReviewDimensions.length
                  )}
                </Text>
              }
            />
          )}
        />
      </ContentContainer>
    </>
  );
};

export default newDataLoader({
  fetch: () => get('/people_review_dimensions'),
  hydrate: {
    peopleReviewDimensions: {},
  },
})(Dimensions);
