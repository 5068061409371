import React, { Fragment } from 'react';

import { BaseTrainingCourse } from 'models/TrainingCourse';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Icon,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import TrainingCoursePicker from 'scenes/components/TrainingCoursePicker';

type Props = {
  areaId: string;
  onClose: () => void;
  onConfirm?: () => void;
  unavailableCourseIds?: string[];
};

const AddSuggestionsModal = ({
  areaId,
  onClose: closeModal,
  onConfirm,
  unavailableCourseIds,
}: Props) => {
  const [courses, setCourses] = React.useState<
    (BaseTrainingCourse | undefined)[]
  >([undefined]);

  const definedCourses = courses.filter(
    (course): course is BaseTrainingCourse => !!course
  );
  const definedCoursesCount = definedCourses.length;

  const dispatch = useAppDispatch();
  const submitNewCourses = async () => {
    await dispatch(
      post(`training/training_course_suggestions/bulk_create`, {
        areaId,
        courseIds: definedCourses.map(course => course.id),
      })
    );

    if (onConfirm) onConfirm();
    closeModal();
  };

  const changeCourse = (
    index: number,
    course: BaseTrainingCourse | undefined
  ) => {
    const newCourses = [...courses];
    newCourses[index] = course;
    setCourses(newCourses);
  };

  const addCourse = () => {
    setCourses([...courses, undefined]);
  };

  const removeCourse = (index: number) => {
    const newCourses = [...courses];
    newCourses.splice(index, 1);
    setCourses(newCourses);
  };

  return (
    <ModalCard isActive isLarge onClose={closeModal}>
      <ModalCardHead>
        <ModalCardTitle>
          {__('Suggest training courses for this skill')}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody className="flex flex-col items-start">
        <Text preset="14s6" className="mb-4">
          {__(
            'Choose the training courses to suggest in the catalog so that employees whose matrix is associated with this skill can see it recommended in the catalog.'
          )}
        </Text>
        <div className="w-full grid grid-cols-[1fr_auto] gap-2 items-center">
          {courses.map((course, index) => (
            <Fragment key={index}>
              <TrainingCoursePicker
                trainingCourse={course}
                unavailableCourseIds={definedCourses
                  .map(course => course.id)
                  .concat(unavailableCourseIds || [])}
                includeUnpublished
                onChange={course => changeCourse(index, course)}
                className="col-start-1"
              />
              {index > 0 && (
                <Icon
                  name="close"
                  onClick={() => removeCourse(index)}
                  className="text-text-soften cursor-pointer col-start-2"
                />
              )}
            </Fragment>
          ))}
        </div>
        <Link onClick={addCourse} isPrimary additionalClassName="mt-2">
          {__('Add a training course')}
        </Link>
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={closeModal}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={submitNewCourses}
          disabled={!definedCoursesCount}
        >
          {n__(
            'Suggest %1 training course',
            'Suggest %1 training courses',
            definedCoursesCount
          )}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default AddSuggestionsModal;
