import React, { useState } from 'react';

import type { SkillsDomain } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  FieldError,
  Input,
  Label,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import useManageableTextList from '../helpers/useManageableTextList';
import DomainPicker from './DomainPicker';

type Props = {
  creationEndpoint: string;
  positionInMatrix?: number;
  initialExpectationTitles?: Array<string>;
  withDomainSelection?: boolean;
  afterCreate: (id: string) => void;
  onClose: () => void;
};

const MAX_EXPECTATIONS_NUMBER = 10;

const SkillAreaCreationModal = ({
  initialExpectationTitles,
  creationEndpoint,
  positionInMatrix,
  withDomainSelection,
  onClose,
  afterCreate,
}: Props) => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<FormErrors>();

  const [area, setArea] = useState<{
    title: string;
    domain: SkillsDomain | null;
  }>({ title: '', domain: null });

  const { items, areItemsUnique, renderItems } = useManageableTextList({
    initialValues: initialExpectationTitles,
    initialPlaceholders: [__('Junior')],
  });

  const createArea = () =>
    handleFormErrors(async () => {
      const { response } = await dispatch(
        post(creationEndpoint, {
          newAreaAttributes: {
            title: area.title,
            expectationsAttributes: items,
            domainId: area.domain?.id,
          },
          position: positionInMatrix,
        })
      );

      afterCreate(response.body.data.id);
    }, setErrors);

  return (
    <ModalCard
      isActive
      onClose={onClose}
      isLarge
      refreshContentOnOpening
      className="skills-form-creation-modal"
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Create a skill')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <Field>
          <Label>{__('Skill title')}</Label>
          <Input
            value={area.title}
            placeholder={__('ex: Product deployment')}
            onChange={(title: string) => setArea({ ...area, title })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <FieldError>{errors?.title}</FieldError>
        </Field>

        {withDomainSelection && (
          <div className="mb-4">
            <Label>{__('Associated domain (optional)')}</Label>
            <DomainPicker
              value={area.domain}
              onChange={(domain: SkillsDomain | null) =>
                setArea({ ...area, domain })
              }
            />
          </div>
        )}

        <Field>
          <Label>{__('Skill levels')}</Label>

          <div className="mb-4">
            {__(
              'Levels define the specific skills or expected outcomes for this skill. You will then link them to the levels of your skills matrices. To learn more about skills, %1.',
              <Link to="https://docs.elevo.fr/" isPrimary openInNewTab>
                {__('visit our help center')}
              </Link>
            )}
          </div>

          {renderItems({
            placeholder: __('ex: Junior'),
            newItemPlaceholder: __('Add a level to the skill...'),
            maxItems: MAX_EXPECTATIONS_NUMBER,
          })}
          <FieldError>{errors?.expectationsTitle}</FieldError>
          <FieldError>{errors?.expectations}</FieldError>
          {!areItemsUnique() && (
            <FieldError>{__('Levels must be unique')}</FieldError>
          )}
        </Field>
      </ModalCardBody>

      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={!areItemsUnique()}
          onClick={createArea}
        >
          {__('Create the skill')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default SkillAreaCreationModal;
