import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ReviewCycle } from 'models/ReviewCycle';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToReviewCycles } from 'helpers/navigation';
import confirmAsync from 'helpers/react/confirmAsync';
import { trackAction } from 'helpers/tracking';
import { zendeskURL } from 'helpers/zendesk';

import { put } from 'redux/actions/api';

import {
  BlueInfoBox,
  DropdownMenuItem,
  Link,
  StrictlySanitizedHtml,
  Text,
} from 'components';

const TEN_DAYS_IN_MILLISECONDS = 10 * 24 * 60 * 60 * 1000;

type Props = {
  reviewCycle: ReviewCycle;
};

const ArchiveCampaignItem = ({ reviewCycle }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const contactSupportWarningMessageEnabled = useMemo(() => {
    if (reviewCycle.interactionType !== 'one_on_one' || !reviewCycle.launchedAt)
      return false;

    const isLaunchedRecently =
      new Date().getTime() - new Date(reviewCycle.launchedAt).getTime() <=
      TEN_DAYS_IN_MILLISECONDS;

    if (isLaunchedRecently) {
      return (
        reviewCycle.defaultTemplate.objectivesDefinitionEnabled ||
        reviewCycle.defaultTemplate.objectivesReviewEnabled
      );
    }
    return false;
  }, [reviewCycle]);

  const documentationURL = zendeskURL({
    en: '4405019224465-Archive-a-campaign',
    fr: '4405019224465-Archiver-une-campagne',
  });

  const modalDescription = contactSupportWarningMessageEnabled ? (
    <>
      {__('By doing so your review campaign will be archived.')}
      <Link
        to={documentationURL}
        isPrimary
        additionalClassName="ml-1"
        openInNewTab
      >
        {__('Visit our help center')}
      </Link>

      <BlueInfoBox className="mt-2">
        <div className="flex flex-col gap-4">
          <Text>
            <StrictlySanitizedHtml
              html={__(
                'If you would <b>modify the campaign</b>, please contact Elevo Support (support@elevo.io).'
              )}
            />
          </Text>
          <Text>
            <StrictlySanitizedHtml
              html={__(
                'We kindly ask you <b>not to launch a new campaign</b> containing objectives <b>in the meantime</b>, to avoid creating inconsistencies in the periods.'
              )}
            />
          </Text>
        </div>
      </BlueInfoBox>
    </>
  ) : (
    __('By doing so your review campaign will be archived.')
  );

  const confirmArchiveReviewCycle = () =>
    confirmAsync(__('Archive review campaign?'), modalDescription, {
      confirmLabel: __('Archive campaign'),
      onConfirm: async () => {
        await archiveCampaign();
        navigate(pathToReviewCycles(reviewCycle.interactionType));
        trackAction('Review cycle ended', {
          reviewCycleId: reviewCycle.id,
        });
      },
    });

  const archiveCampaign = async () => {
    const reviewCycleName = reviewCycle.name;
    invariant(reviewCycleName, 'reviewCycle should have a name');
    await dispatch(
      put(
        `review_cycles/${reviewCycle.id}`,
        { status: 'ended' },
        {
          errorMessage: __("Sorry, we couldn't archive the campaign"),
          successMessage: __(
            '<b>%1 has been archived</b>👍 <br/>Corresponding reviews have been archived for participants of this campaign.',
            reviewCycle.name
          ),
        }
      )
    );
  };

  return (
    <DropdownMenuItem onClick={confirmArchiveReviewCycle}>
      {__('Archive campaign')}
    </DropdownMenuItem>
  );
};

export default ArchiveCampaignItem;
