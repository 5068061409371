import React from 'react';

import type { TrainingSession, TrainingSessionSnapshot } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { pathToTrainingSessionDetails } from 'helpers/navigation';

import { Link, Text } from 'components';

type Props = {
  session: TrainingSession | TrainingSessionSnapshot | null | undefined;
};

const TrainingSessionNameTableCell = ({ session }: Props) => {
  if (!session) {
    return (
      <Text
        transformation="italic"
        color="light"
        testClassName="test-session-name"
      >
        {__('Not linked to a training')}
      </Text>
    );
  }

  if (
    session.type === 'trainingSessionSnapshot' ||
    !can({ perform: 'update', on: session })
  ) {
    return (
      <Text testClassName="test-session-name">
        {session.name || __('Untitled session')}
      </Text>
    );
  }

  return (
    <Link
      to={pathToTrainingSessionDetails(session.id)}
      openInNewTab
      withOpenInNewTabIcon={false}
      testClassName="test-session-name"
    >
      {session.name || __('Untitled session')}
    </Link>
  );
};

export default TrainingSessionNameTableCell;
