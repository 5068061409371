import React from 'react';

import type { OneOnOneUserReviewFile } from 'models/UserReview/OneOnOneUserReview';

import colors from 'styles/colors';

import { Button, Icon, Link, Text, TooltipOnEllipsis } from 'components';

type Props = {
  file: OneOnOneUserReviewFile;
  onDelete: () => void;
  showDeleteButton: boolean;
  canDelete: boolean;
};

const FileCell = ({ file, onDelete, canDelete, showDeleteButton }: Props) => (
  <div
    className="flex items-center justify-between rounded px-4 py-2 mb-2"
    style={{ backgroundColor: colors.feedbackBg }}
  >
    <Text preset="14bs6" className="overflow-hidden">
      <Link
        to={file.url}
        isPrimary
        openInNewTab
        additionalClassName="whitespace-nowrap flex gap-1"
      >
        <TooltipOnEllipsis>{file.name}</TooltipOnEllipsis>
      </Link>
    </Text>
    <div className="shrink-0">
      <Text
        preset="14s6"
        color="light"
        style={{ marginRight: 8, marginLeft: 9 }}
      >
        ({file.size})
      </Text>
      {showDeleteButton && (
        <Button
          disabled={!canDelete}
          className="border-none p-1"
          style={{ backgroundColor: colors.feedbackBg }}
          onClick={onDelete}
        >
          <Icon className="text-nav-icon" name="delete" />
        </Button>
      )}
    </div>
  </div>
);

export default FileCell;
