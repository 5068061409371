import React from 'react';

import type { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import {
  Flex,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  SupportLink,
  Text,
  Title4,
} from 'components';

type Props = {
  title: string;
  description: string | ReactNode;
  mainButtonText: string;
  prefilledSubjectToSupport: string;
  svg: string;
  isActive: boolean;
  onClose: () => void;
};

const FeaturePromptModal = ({
  title,
  description,
  mainButtonText,
  prefilledSubjectToSupport,
  svg,
  isActive,
  onClose,
}: Props) => {
  const content = (
    <Flex direction="column" verticalAlign horizontalAlign>
      <img
        style={{ flexShrink: 0, height: 160, width: 160 }}
        src={`data:image/svg+xml;base64,${window.btoa(svg)}`}
        alt={title}
      />

      <Title4 style={{ marginTop: 16, marginBottom: 16 }}>{title}</Title4>
      {typeof description === 'string' ? (
        <Text align="left">{description}</Text>
      ) : (
        description
      )}
    </Flex>
  );

  const footer = (
    <Flex horizontalAlign direction="column">
      <SupportLink
        className="button is-primary"
        prefilledSubjectToSupport={prefilledSubjectToSupport}
        style={{ marginBottom: 16 }}
      >
        {mainButtonText}
      </SupportLink>

      <Link isPrimary onClick={onClose}>
        {__('Close')}
      </Link>
    </Flex>
  );

  return (
    <ModalCard
      isLarge
      isActive={isActive}
      refreshContentOnOpening={false}
      onClose={onClose}
    >
      <ModalCardHead onClose={onClose} />
      <ModalCardBody>{content}</ModalCardBody>
      <ModalCardFooter>{footer}</ModalCardFooter>
    </ModalCard>
  );
};

export default FeaturePromptModal;
