import React, { useContext } from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon, Link } from 'components';

import { DataContext } from '.';

const NoRecordState = () => {
  const { setRequestCreationModalActive } = useContext(DataContext);

  return (
    <EmptyStateWithIcon
      inBox={false}
      iconName="send"
      title={__('There is no training request for this period.')}
      description={__(
        'To create a training request, you can click %1.',
        <Link isPrimary onClick={() => setRequestCreationModalActive(true)}>
          {__('here')}
        </Link>
      )}
    />
  );
};

export default NoRecordState;
