import { sortBy } from 'lodash';
import React from 'react';

import type { InteractionType } from 'models';
import type {
  ReviewBlockUpdateParams,
  SkillsCareerLevelBlock,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Helper,
  Icon,
  Link,
  Text,
  Title,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import BlockActions from './components/BlockActions';
import BlockContent from './components/BlockContent';
import FeedbackOptionsSection from './components/FeedbackOptionsSection';

type Props = {
  block: SkillsCareerLevelBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  hasSelfEvaluationEnabled: boolean;
  interactionType: InteractionType;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
};

export default function SkillsCareerLevelComponent({
  block,
  onChange,
  hasSelfEvaluationEnabled,
  interactionType,
  ...actionProps
}: Props) {
  const {
    revieweeFeedbackOptions,
    reviewerFeedbackOptions,
    ratingOptions,
    content,
    richTextEnabled,
  } = block;

  return (
    <Box testClassName="test-dropdown-question-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Flex>
          {/* line height is 22px to be aligned with text */}
          <Icon
            style={{ lineHeight: '22px', marginRight: 8 }}
            name="table_chart"
          />
          <Title6 transformation="uppercase">{__('Skills')}</Title6>
        </Flex>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'dropdown_question_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnchange => (
          <BlockContent
            onChange={autoSavingOnchange}
            content={content}
            withRichText={richTextEnabled}
          />
        )}
      />
      <Helper>
        <Text>
          <p>
            {__(
              'Each collaborator will be evaluated based on the competency matrices to which they are attached.'
            )}
          </p>
          <p>
            {__(
              'It is essential to ensure that every collaborator is linked to a skill matrix before launching the evaluation campaign. To attach a collaborator, %1',
              <Link to="https://docs.elevo.fr/" isPrimary openInNewTab>
                {__('please refer to this article in our help center.')}
              </Link>
            )}
          </p>
        </Text>
      </Helper>

      <div className="test-everyone-rating-option-list text-list">
        <Title size={7} transformation="uppercase">
          {__('Rating scale')}
        </Title>
        <div style={{ marginBottom: 8 }}>
          {__('It is defined in the configuration of your organization')}
        </div>
        {sortBy(ratingOptions, 'position').map(item => (
          <Flex verticalAlign style={{ width: '100%' }}>
            <div className="text-list-item">
              <Text className="item-position">{item.position + 1}.</Text>
              <div style={{ width: '100%' }}>
                <Text className="label inline-editable">{item.label}</Text>
              </div>
            </div>
          </Flex>
        ))}
      </div>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <FeedbackOptionsSection
        revieweeFeedbackOptions={revieweeFeedbackOptions}
        reviewerFeedbackOptions={reviewerFeedbackOptions}
        hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
        interactionType={interactionType}
        blockType={block.blockType}
      />
    </Box>
  );
}
